<template>
    <div
        class="page-tools"
        v-if="navigation !== false"
    >
        <div
            v-if="data.tools.print"
            class="page-tools__item"
        >
            <a
                href="javascript: void(0);"
                @click="print()"
            >
                <z-icon
                    name="tools/print"
                    width="32"
                    height="32"
                />
                <span class="page-tools__text">{{ text.print }}</span>
            </a>
        </div>
        <div
            v-if="data.tools.share"
            class="page-tools__item"
        >
            <tippy-tooltip
                placement="right"
                :trigger="getEventForDevice"
                append-to="parent"
                :interactive="true"
            >
                <template v-slot:trigger>
                    <a class="page-tools__link" href="javascript: void(0);">
                        <z-icon
                            name="tools/share"
                            color="#c5c8cd"
                            dir="down"
                            width="33"
                            height="33"
                        />
                        <span class="page-tools__text">{{ text.share }}</span>
                    </a>
                </template>
                <z-share
                    :services="['vkontakte']"
                    :lang="$root.lang"
                    bare
                />
            </tippy-tooltip>
        </div>
    </div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'page-tools',
    mixins: [mixinDevice],
    data () {
        return {
            data: this.$root.app.components['navigation-left'],
            text: {
                share: this.$root.lang === 'ru' ? 'Поделиться' : 'Share',
                print: this.$root.lang === 'ru' ? 'Распечатать' : 'Print'
            }
        }
    },
    computed: {
        navigation () {
            return (this.data !== undefined) ? this.data : false
        },
        getEventForDevice () {
            if (this.isDesktop()) {
                return 'mouseenter'
            } else {
                return 'click'
            }
        },
        parent () {
            return this.$refs.pageToolsShare
        }
    },
    methods: {
        print () {
            window.print()
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
